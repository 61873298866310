import React, { useEffect } from "react";
import { TimelineLite } from "gsap";
import { makeStyles } from "@material-ui/styles";
import { Card, Grid } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import SendMessageForm from "./../components/SendMessageForm";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
// import FacebookIcon from "@material-ui/icons/Facebook";
import GitHubIcon from "@material-ui/icons/GitHub";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: "30px",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  cardActionsRoot: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  cardContentRoot: {
    backgroundColor: "rgba(243, 167, 18, 0.85)",
  },
  contentText: {
    fontSize: 15,
    fontWeight: 900,
  },
}));

export default function ResumeContact() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const classes = useStyles();
  useEffect(() => {
    var timeline = new TimelineLite();
    timeline.from("#firstCard", 1.5, {
      x: -500,
      ease: "circ.out",
    });
  }, []);
  return (
    <div className={classes.blogWrapper}>
      <Grid container justify="center" id="firstCard">
        <Grid item xs={10} md={10}>
          <Card>
            <Grid container justify="center" className={classes.formContainer}>
              <Grid item xs={10} md={6}>
                <SendMessageForm />
                <CardActions
                  disableSpacing
                  classes={{
                    root: classes.cardActionsRoot,
                  }}
                >
                  <IconButton
                    href="https://github.com/fawzy-tat/"
                    target="_blank"
                    aria-label="add to favorites"
                  >
                    <GitHubIcon color="secondary" />
                  </IconButton>
                  <IconButton
                    href="https://www.linkedin.com/in/fawzytat/"
                    target="_blank"
                    aria-label="share"
                  >
                    <LinkedInIcon color="secondary" />
                  </IconButton>
                  {/* <IconButton
                    aria-label="share"
                    href="https://www.facebook.com/FawzyTaT"
                    target="_blank"
                  >
                    <FacebookIcon color="secondary" />
                  </IconButton> */}
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                    color="secondary"
                  >
                    <ContactPhoneIcon />
                  </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography
                      variant="body2"
                      className={classes.contentText}
                      color="primary"
                      component="p"
                    >
                      Email: fawzytatdev@gmail.com
                    </Typography>
                    <Typography
                      variant="body2"
                      className={classes.contentText}
                      color="primary"
                      component="p"
                    >
                      Phone: (323) 712 5693
                    </Typography>
                  </CardContent>
                </Collapse>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
