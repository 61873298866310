import React from "react";
import { BrowserRouter } from "react-router-dom";

// Material UI Theme
import { ThemeProvider } from "@material-ui/styles";

// Redux
import { createStore } from "redux";
import allReducers from "./store/reducers";
import { Provider } from "react-redux";

// Localization

import Main from "./Main";
import FawzyTheme from "./themes/FawzyTheme";

let store = createStore(
  allReducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider theme={FawzyTheme}>
          <Main></Main>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
