import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
    marginBottom: "10px",
    // backgroundImage: `url(${cardTexture})`,
    // backgroundSize: "cover"
  },

  headerRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  cardActionsRoot: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  cardContentRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  headerTitle: {
    color: "rgba(243, 167, 18, 1)",
    fontWeight: 900,
    fontSize: "1.2rem",
  },
  headerSubHeader: {
    fontWeight: 900,
    color: "#262626",
    fontSize: "0.9rem",
  },

  textSecondary: { color: "#e4e4e2", marginTop: "10px" }, //white gray
}));

export default function IntroCard() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader
        title="Hi there!"
        classes={{
          title: classes.headerTitle,
          subheader: classes.headerSubHeader,
          root: classes.headerRoot,
        }}
      />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="body2"
              className={classes.textSecondary}
              component="p"
            >
              Senior Full Stack Engineer with 8+ years of experience working on
              enterprise/start-up level web and mobile applications. Expertise
              in software architecture and system design. Specializing in
              JavaScript with both SQL and NoSQL databases, building both
              RESTful and GraphQL APIs using AWS and other cloud providers.
              Built both monolithic and microservices infrastructures that
              scale.
            </Typography>
            {/* <Typography
              variant="body2"
              className={classes.textSecondary}
              component="p"
            >
              I Started coding using PHP using different framworks but Laravel
              was the best at the time, I personally admire what{" "}
              <strong>"Taylor Otwell"</strong> has done with laravel, Now I code
              with javsacript all the time.
            </Typography> */}
            <Typography
              variant="body2"
              className={classes.textSecondary}
              component="p"
            >
              Being born and raised in Cairo, Egypt, I'm believed by some to be the real Moon Knight! could it be true!?
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
