import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import CodeIcon from "@material-ui/icons/Code";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paragraphText: {
    fontSize: ".83rem !important",
  },
  paragraphTextChild: {
    fontSize: ".83rem !important",
    marginTop: "5px !important",
  },
  visitBtn: {
    marginTop: "10px",
  },
  white: {
    color: "#fff",
    // backgroundColor: "#fff",
    borderColor: "#fff",
    "&:hover": {
      borderColor: "#FFF",
    },
  },
}));

export default function ProjectsTimeLine() {
  const classes = useStyles();
  const WorkPlaces = [
    {
      title: "Analytics Engagements Reporting System",
      subtitle: "Reporting Web App ( Internal Project )",
      date: "2019-2020",
      paragraph1:
        "- Developed with React/Redux for the front-end and ExpressJS on the backend with sequelize as an ORM and AWS RDS as a DB storage.",
      paragraph2: "- Integration with Google Reporting Core API",
      paragraph3: "- Integration with Google Console API",
      paragraph4: "- Integration with Facebook Pixels, Instagram APIs",
      paragraph5: "- Integration with Ring Central API.",
      paragraph6: "- Integration with Twitter API.",
      paragraph7: "- AWS RDS instace for the DB and AWS EC2 as a server",
      paragraph8: "- Code documentation with jsdoc",
      paragraph9:
        "- Radio and TV schedule managment with Restful API CRUD operations",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "app.midoctoralamano.com",
      subtitle: "Telemedicine Registeration system",
      date: "2019",
      paragraph1:
        "- Developed with React/Redux for the front-end and Laravel on the backend.",
      paragraph2: "- Integration with Authorize.net as a payment gateway.",
      paragraph3: "- Integration with mytelemedicine.com for the main service.",
      paragraph4: "- JWT Auth",
      paragraph5: "- Material-UI components.",
      paragraph6: "- Transactions and Subscription System with Cronjobs.",
      paragraph7: "- Laravel Forge for deployment with Digital Ocean instance",
      paragraph8: "- Solid Principals Implemented on the Backend",
      paragraph9: "- GTM tags and triggers",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
      site: "https://app.midoctoralamano.com/",
    },
    {
      title: "laligadefensora.com",
      subtitle: "Legal Firm - Leads management system",
      date: "2019",
      paragraph1: "- Leads management system for an existing wordpress site.",
      paragraph2: "- Multi tunnel leads system with Zapier.",
      paragraph3: "- Integration with Airtables",
      paragraph4: "- Integration with Mailgun and Gmail API",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
      site: "https://laligadefensora.com/",
    },
    {
      title: "WhoosOut",
      subtitle: "Ionic Mobile app",
      date: "2019",
      paragraph1: "- Event Planning and Booking App.",
      paragraph2: "- Developed with Ionic Typescript.",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "dev.beos.global Global Thrive System",
      subtitle: "Business Evaluation and Management system",
      date: "2017",
      paragraph1: "- Developed with Laravel 5.6 and Angular.js .",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
      site: "https://dev.beos.global/",
    },
    {
      title: "OREX",
      subtitle: "Real-estate listing & renting system",
      date: "2017",
      paragraph1: "- Developed with Laravel 5.5 and Vue.js.",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "paymestore.co",
      subtitle: "Payment gateway",
      date: "2016",
      paragraph1: "- Receive Payments Online in JUST 3 Minutes!",
      paragraph2: "- Developed with Laravel 5.3",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
      site: "https://www.paymestore.co/",
    },
    {
      title: "Classbug.com",
      subtitle: "Payment gateway",
      date: "2018",
      paragraph1: "- Teacher class assistant scheduling system!",
      paragraph2: "- Developed with Vanilla PHP with Jquery- CSS - Bootsrap",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
      site: "https://classbug.com/",
    },
  ];

  return (
    <VerticalTimeline>
      {WorkPlaces.map((WorkPlace) => {
        return (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: WorkPlace.contentBG,
              color: WorkPlace.contentColor,
            }}
            contentArrowStyle={{
              borderRight: WorkPlace.arrowStyle,
            }}
            date={WorkPlace.date}
            iconStyle={{ background: "#f3a712", color: "#821c1c" }}
            icon={<CodeIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              {WorkPlace.title}
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              {WorkPlace.subtitle}
            </h4>
            {WorkPlace.paragraph1 ? (
              <p className={classes.paragraphText}>{WorkPlace.paragraph1}</p>
            ) : null}
            {WorkPlace.paragraph2 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph2}
              </p>
            ) : null}
            {WorkPlace.paragraph3 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph3}
              </p>
            ) : null}
            {WorkPlace.paragraph4 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph4}
              </p>
            ) : null}
            {WorkPlace.paragraph5 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph5}
              </p>
            ) : null}
            {WorkPlace.paragraph6 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph6}
              </p>
            ) : null}
            {WorkPlace.paragraph7 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph7}
              </p>
            ) : null}
            {WorkPlace.paragraph8 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph8}
              </p>
            ) : null}
            {WorkPlace.paragraph9 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph9}
              </p>
            ) : null}
            {WorkPlace.site ? (
              <Grid container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  target="_blank"
                  href={WorkPlace.site}
                  classes={{
                    outlinedPrimary: classes.white,
                    text: classes.white,
                    focusVisible: classes.white,
                  }}
                  className={classes.visitBtn}
                >
                  Visit Site
                </Button>
              </Grid>
            ) : null}
          </VerticalTimelineElement>
        );
      })}

      <VerticalTimelineElement
        iconStyle={{ background: "#f3a712", color: "#fff" }}
        icon={<CodeIcon />}
      />
    </VerticalTimeline>
  );
}
