import React, { useEffect } from "react";
import { TimelineLite } from "gsap";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({}));

export default function ResumeBlog() {
  const classes = useStyles();
  useEffect(() => {
    var timeline = new TimelineLite();
    timeline.from("#firstCard", 1.5, {
      x: -500,
      ease: "circ.out",
    });
  });
  return (
    <div className={classes.blogWrapper}>
      <div className={classes.firstCard} id="firstCard">
        <h2>Blog div</h2>
      </div>
    </div>
  );
}
