import React from "react";
import Particles from "react-particles-js";
// import svgex1 from "../assets/img/vectors/1.svg";
import svgex2 from "../assets/img/vectors/2.svg";
// import svgex3 from "../assets/img/vectors/3.svg";
import svgex4 from "../assets/img/vectors/4.svg";
// import svgex5 from "../assets/img/vectors/5.svg";
import svgex6 from "../assets/img/vectors/6.svg";
import svgex7 from "../assets/img/vectors/7-curly.svg";
import svgex9 from "../assets/img/vectors/9-sheild.svg";
import svgex10 from "../assets/img/vectors/10-person.svg";
import svgex11 from "../assets/img/vectors/11-tag.svg";
import svgex12 from "../assets/img/vectors/12-bug.svg";
import svgex13 from "../assets/img/vectors/13-plugin.svg";
import svgex14 from "../assets/img/vectors/14-log-screen.svg";
import svgex15 from "../assets/img/vectors/15-hashtag.svg";
import svgex16 from "../assets/img/vectors/16-zap.svg";
import svgex17 from "../assets/img/vectors/17-brain.svg";
import svgex18 from "../assets/img/vectors/18-server.svg";
import svgex19 from "../assets/img/vectors/19-responsive.svg";
import svgex20 from "../assets/img/vectors/20-db.svg";
import svgex21 from "../assets/img/vectors/21-api.svg";
import svgex22 from "../assets/img/vectors/22-cloud.svg";
import svgex23 from "../assets/img/vectors/23-robot.svg";
import svgex24 from "../assets/img/vectors/24-vscode.svg";
import svgex25 from "../assets/img/vectors/25-nodejs.svg";
import svgex26 from "../assets/img/vectors/26-js.svg";
// import svgex9 from "../assets/img/vectors/9.svg";

export default function ParticlesDeveloper() {
  return (
    <Particles
      height="100vh"
      params={{
        particles: {
          number: {
            value: 70,
            density: {
              enable: true,
              value_area: 800
            }
          },
          line_linked: {
            enable: false,
            color: "#e12828"
          },
          move: {
            speed: 3,
            out_mode: "out"
          },
          shape: {
            // type: ["images", "circle"],
            type: ["images"],
            images: [
              {
                src: svgex2,
                height: 15,
                width: 15
              },
              {
                src: svgex4,
                height: 15,
                width: 15
              },
              {
                src: svgex6,
                height: 15,
                width: 15
              },
              {
                src: svgex7,
                height: 15,
                width: 15
              },
              {
                src: svgex9,
                height: 15,
                width: 15
              },
              {
                src: svgex10,
                height: 5,
                width: 5
              },
              {
                src: svgex11,
                height: 15,
                width: 15
              },
              {
                src: svgex12,
                height: 15,
                width: 15
              },
              {
                src: svgex13,
                height: 20,
                width: 20
              },
              {
                src: svgex14,
                height: 15,
                width: 15
              },
              {
                src: svgex15,
                height: 20,
                width: 20
              },
              {
                src: svgex16,
                height: 20,
                width: 20
              },
              {
                src: svgex17,
                height: 15,
                width: 15
              },
              {
                src: svgex18,
                height: 15,
                width: 15
              },
              {
                src: svgex19,
                height: 20,
                width: 20
              },
              {
                src: svgex20,
                height: 15,
                width: 15
              },
              {
                src: svgex21,
                height: 15,
                width: 15
              },
              {
                src: svgex22,
                height: 15,
                width: 15
              },
              {
                src: svgex23,
                height: 15,
                width: 15
              },
              {
                src: svgex24,
                height: 15,
                width: 15
              },
              {
                src: svgex25,
                height: 4,
                width: 15
              },
              {
                src: svgex26,
                height: 15,
                width: 15
              }
            ]
          },
          color: {
            value: "#fff"
          },
          opacity: {
            value: 1
          },
          size: {
            value: 25,
            random: false,
            anim: {
              enable: true,
              speed: 4,
              size_min: 10,
              sync: false
            }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "bubble"
            },
            onclick: {
              enable: true,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 800,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 60,
              size: 80,
              duration: 2,
              opacity: 0.8,
              speed: 3
            },
            repulse: {
              distance: 60,
              duration: 0.4
            },
            push: {
              particles_nb: 4
            },
            remove: {
              particles_nb: 2
            }
          }
        },
        retina_detect: true
      }}
    />
  );
}
