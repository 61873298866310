import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import LogoOnlyEyes from "../assets/img/logo/only-eyes.png";

import { Button, Grid } from "@material-ui/core";
import CssTextField from "./../components/CssTextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import { FormSchema } from "../schemas/Validator";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  SendMessageButton: {
    padding: "10px 5px 10px 5px",
    marginTop: "50px",
    width: "80%",
    fontWeight: 900,
    fontSize: "1rem",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SendMessageForm = withRouter(({ history, ...props }) => {
  const classes = useStyles();
  const [form, setForm] = React.useState({
    name: "",
    business: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = React.useState({});
  const [alertopen, setAlertopen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertopen(false);
  };

  // The form inputs
  const handleChange = ({ currentTarget: input }) => {
    const messageForm = { ...form };
    messageForm[input.name] = input.value;
    setForm(messageForm);
  };

  const handleSubmit = async () => {
    const newErrorsObj = {};
    const options = { abortEarly: false, allowUnknown: true };
    const validationErrors = FormSchema.validate(form, options);
    if (!validationErrors.error) {
      setErrors(newErrorsObj);

      const response = await axios.post("https://api.fawzytatdev.com/lead", {
        service: "fawzytatdev",
        input1: form.name,
        input2: form.business,
        input3: form.email,
        input4: form.phone,
        input5: form.message,
      });
      console.log("response", response);
      setAlertopen(true);
      setTimeout(() => {
        props.close();
      }, 3000);
    } else {
      validationErrors.error.details.map((item) => {
        return (newErrorsObj[item.path[0]] = item.message);
      });
      setErrors(newErrorsObj);
    }
  };
  return (
    <>
      <Grid container direction="row" justify="center">
        <img src={LogoOnlyEyes} alt="mini-me" height="70" />
      </Grid>

      <form noValidate autoComplete="off">
        <CssTextField
          id="Name"
          label="Name"
          name="name"
          value={form.name}
          color="secondary"
          onChange={handleChange}
          helperText={errors.name ? errors.name : null}
          fullWidth
        />
        <CssTextField
          id="Business"
          label="Business Type"
          name="business"
          value={form.business}
          onChange={handleChange}
          helperText={errors.business ? errors.business : null}
          color="secondary"
          fullWidth
        />
        <CssTextField
          id="Phone"
          label="Phone"
          name="phone"
          value={form.phone}
          onChange={handleChange}
          helperText={errors.phone ? errors.phone : null}
          color="secondary"
          fullWidth
        />
        <CssTextField
          id="Email"
          label="Email"
          name="email"
          value={form.email}
          onChange={handleChange}
          helperText={errors.email ? errors.email : null}
          color="secondary"
          fullWidth
        />
        <CssTextField
          id="Message"
          multiline
          rowsMax="5"
          label="Message"
          name="message"
          value={form.message}
          onChange={handleChange}
          helperText={errors.message ? errors.message : null}
          color="secondary"
          fullWidth
        />
        <Grid container direction="row" justify="center">
          <Button
            id="SendMessageBtn"
            variant="contained"
            color="secondary"
            size="large"
            elevation={0}
            onClick={handleSubmit}
            className={classes.SendMessageButton}
          >
            Send Message
          </Button>
        </Grid>

        <Snackbar
          open={alertopen}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} color="success" severity="success">
            Thank you for sending the message, I'll be reaching out to you ASAP
            !
          </Alert>
        </Snackbar>
      </form>
    </>
  );
});

export default SendMessageForm;
