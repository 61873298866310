import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
// import FacebookIcon from "@material-ui/icons/Facebook";
import GitHubIcon from "@material-ui/icons/GitHub";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import cardTexture from "../assets/img/bg/53.jpg";
import profilePic from "../assets/img/logo/logo-circle-min.png";
// import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
    // backgroundImage: `url(${cardTexture})`,
    backgroundSize: "cover",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#821c1c",
  },
  headerTitle: {
    color: "#821c1c",
    fontWeight: 900,
    fontSize: "1.2rem",
  },
  headerSubHeader: {
    fontWeight: 900,
    color: "#262626",
  },
  headerRoot: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  cardActionsRoot: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
  cardContentRoot: {
    backgroundColor: "rgba(243, 167, 18, 0.85)",
  },
  contentText: {
    fontSize: 15,
    fontWeight: 900,
  },
}));

export default function ProfileCard() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="#" variant="rounded" className={classes.avatar}>
            #
          </Avatar>
        }
        action={
          <IconButton aria-label="settings" color="secondary">
            <MoreVertIcon />
          </IconButton>
        }
        title="Fawzy Atwa"
        subheader="Senior Fullstack Engineer - Associate Solution architect"
        classes={{
          title: classes.headerTitle,
          subheader: classes.headerSubHeader,
          root: classes.headerRoot,
        }}
      />
      <CardMedia
        className={classes.media}
        image={profilePic}
        title="Paella dish"
      />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Typography
          variant="body2"
          className={classes.contentText}
          color="primary"
          component="p"
        >
          Senior Software engineer, Los Angeles, CA
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        classes={{
          root: classes.cardActionsRoot,
        }}
      >
        <IconButton
          href="https://github.com/fawzy-tat/"
          target="_blank"
          aria-label="add to favorites"
        >
          <GitHubIcon color="secondary" />
        </IconButton>
        <IconButton
          href="https://www.linkedin.com/in/fawzytat/"
          target="_blank"
          aria-label="share"
        >
          <LinkedInIcon color="secondary" />
        </IconButton>
        {/* <IconButton
          aria-label="share"
          href="https://www.facebook.com/FawzyTaT"
          target="_blank"
        >
          <FacebookIcon color="secondary" />
        </IconButton> */}
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          color="secondary"
        >
          <ContactPhoneIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            variant="body2"
            className={classes.contentText}
            color="primary"
            component="p"
          >
            Email: fawzytatdev@gmail.com
          </Typography>
          <Typography
            variant="body2"
            className={classes.contentText}
            color="primary"
            component="p"
          >
            Phone: (323) 712 5693
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
