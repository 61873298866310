const Joi = require("@hapi/joi");
// import Joi from "joi-browser";

const FormSchema = Joi.object({
  name: Joi.string()
    .required()
    .label("Name"),

  phone: Joi.number()
    .required()
    .label("Phone"),
  message: Joi.string()
    .required()
    .label("Message"),

  business: Joi.string()
    .required()
    .label("Business"),

  email: Joi.string()
    .email({
      minDomainSegments: 2,
      tlds: { allow: ["com", "net"] }
    })
    .label("Email")
});

export { FormSchema };
