import React from "react";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import NotFound from "./NotFound";
import ResumeAbout from "./ResumeAbout";
import ResumeSkills from "./ResumeSkills";
import ResumeWork from "./ResumeWork";
import ResumeBlog from "./ResumeBlog";
import ResumeContact from "./ResumeContact";
import { Typography } from "@material-ui/core";
import NavIcons from "../components/NavIcons";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "4fr 22fr 2fr 3fr",
    gridTemplateRows: "75px auto 1fr",
    gridColumnGap: "10px",
    gridTemplateAreas: `
    'sidebar head head head'
    'sidebar pageTitle pageTitle pageTitle'
    'sidebar content icons endgap'
    `,
  },
  smWrapper: {
    // paddingLeft: 10,
    // alignItems: "center",
    justifyItems: "center",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "75px auto 1fr",
    gridColumnGap: "10px",
    gridTemplateAreas: `
    'head'
    'icons'
    'pageTitle'
    'content'
    `,
  },
  contentArea: {
    gridArea: "content",
  },
  iconsDiv: {
    gridArea: "icons",
    paddingLeft: "10px",
  },
  sideBarWrapper: {
    gridArea: "sidebar",
    visibility: "hidden",
  },
  pageTitle: {
    // VERY GOOD EXAMPLE ON USING BOTH GRID AREA AND COLUMN START
    textTransform: "capitalize",
    gridArea: "pageTitle",
    // gridColumnStart: 2,
    padding: "10px 10px 10px 10px",
  },
}));

const Resume = withRouter(({ history }) => {
  const classes = useStyles();
  const smatches = useMediaQuery("(max-width:900px)");
  const pagepath = history.location.pathname.substring(8);
  const pageTitle = () => {
    if (pagepath === "" || pagepath === "about") {
      return "About me";
    }
    return pagepath;
  };

  return (
    <div className={smatches ? classes.smWrapper : classes.wrapper}>
      <div id="sideBarWrapper" className={classes.sideBarWrapper}>
        {/* KEEP IT AS A PLACE HOLDER FOR NOW */}
      </div>
      <div className={classes.pageTitle}>
        <Typography variant="h5" color="textSecondary">
          >_ {pageTitle()}
        </Typography>
      </div>
      <div className={classes.contentArea}>
        <Switch>
          <Route exact path="/resume" component={ResumeAbout} />
          <Route path="/resume/about" component={ResumeAbout} />
          <Route path="/resume/skills" component={ResumeSkills} />
          <Route path="/resume/work" component={ResumeWork} />
          <Route path="/resume/blog" component={ResumeBlog} />
          <Route path="/resume/contact" component={ResumeContact} />
          <Route path="*" component={NotFound} />
        </Switch>
      </div>
      <div className={classes.iconsDiv}>
        <NavIcons pagepath={pagepath} />
      </div>
    </div>
  );
});

export default Resume;
