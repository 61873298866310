import React from "react";
import { Route, Switch } from "react-router-dom";

// Pages
import Landing from "./pages/Landing";
import Resume from "./pages/Resume";
import NotFound from "./pages/NotFound";

const Main = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route path="/resume" component={Resume} />
        <Route fdggfhjdfgpath="*" component={NotFound} />
      </Switch>
    </>
  );
};
export default Main;
