import React from "react";

import { makeStyles } from "@material-ui/styles";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import CodeIcon from "@material-ui/icons/Code";

const useStyles = makeStyles((theme) => ({
  paragraphText: {
    fontSize: ".83rem !important",
  },
  paragraphTextChild: {
    fontSize: ".83rem !important",
    marginTop: "5px !important",
  },
}));

export default function JobsTimeLine() {
  const classes = useStyles();
  const WorkPlaces = [
    {
      title: "Senior Fullstack Engineer",
      subtitle: "Fount, Culver City, CA",
      date: "August 2021 - present",
      paragraph1:
        "- Designed and developed highly scalable RESTful and GraphQL APIs, both for internal and external  consumption, Leveraging AWS infrastructure and high-performance frameworks such as Fastify Node.js, AppSync along with other tools.",
      paragraph2:
        "- Built and architected APIs and microservices for FLYKITT, a jet lag solution mobile app.",
      paragraph3:
        "- Introduced, developed, and integrated various backend solutions using AWS tools, Integrated external tools including Auth0, Twilio, and Retool. ",
      paragraph4:
        "- User Customized Supplements Ordering System, Facilitated production and shipping processes by integrating with AWS infrastructure, ATP tools, and ShipStation. ",
      paragraph5:
        "- Authentication System Implementation: Implemented a robust authentication system to secure APIs and mobile apps using Auth0. Ensured a seamless and secure user experience. ",
      paragraph6:
        "- SaaS Product Expertise: Worked extensively with SaaS products like Retool, Auth0, and Twilio, Leveraged these tools to enhance and streamline various aspects of software development. ",
      paragraph7:
        "- Vector Search Embedding System: Designed and built a sophisticated vector search embedding system on a PostgreSQL database,Implemented advanced search capabilities for enhanced data retrieval.  ",
      paragraph8:
        "- Notification System Development: Designed and built a comprehensive notification system encompassing push, email, and text notifications, Leveraged AWS and Firebase to ensure seamless and reliable communication channels.  ",
      paragraph9:
        "- AI-Powered Slack Bots: Developed AI-powered Slack bots to enhance communication and automation within the organization,Created bots for API alerts as well as others for querying our internal knowledge database, improving operational efficiency. .  ",
      // paragraph4:
      //   "- Provided a soultion and implementation for a program cloning process by building an Event-Based microservice using  Web-sockets, SQS, SES, APIgateway and Lambda. ",
      // paragraph5:
      //   "- Introducing new Javascript frameworks such as Fastify and Prisma, Pipelined by CircleCI. ",
      // paragraph6:
      //   "- Working with IAC using Terraform to deploy infrastructure updates to AWS. ",
      // paragraph7:
      //   "- Implementing system security best practices using aws tools such as Secret Manager. ",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "Senior Fullstack Engineer",
      subtitle: "Scale Media inc , Burbank , CA",
      date: "2020 - 2021",
      paragraph1:
        "- Upgrading Scale Media’s legacy internal sales system into a modern web app using typescript react for the front-end and a slim framework API - structured around the JSON:API format",
      paragraph2:
        "- Building a library of react UI reusable components coded in Typescript.",
      paragraph3:
        "- Maintaining the company's current e-commerce system, working closely with the product marketing and the data analysis teams to build new features and fix current issues. ",
      paragraph4:
        "- Building new brands based on the company's legacy Zend PHP system while refactoring old code and cleaning the mess. ",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "Fullstack Developer",
      subtitle: "711 Media & Marketing, Pasadena, CA",
      date: "Dec 2018 - Jul 2020",
      paragraph1:
        "- Developing a full registration system for an online telemedicine service with multiple subscription plans, integration with ( Authorize.net ) for payment",
      paragraph2:
        "- Building analytics and engagments reporting system that mirrors an existing Radio and Tv schedules.",
      paragraph3: "- Maintain existing web projects.",
      paragraph4: "- Scraping data from diffrent lead systems and APIs.",
      paragraph5:
        "- Integrating and implementing GTM tags and triggers on different web apps.",
      paragraph6:
        "- Handling and maintaining the company servers and setting up containers for new projects.",
      paragraph7:
        "- Introducing new versions of laravel like 5.9 , 6, as well as expressJS,React and Serverless functionalities",
      paragraph8:
        "- Introducing technologies like Zapier, Airtables , Docker , Twilio and many other services that speeds up the development process",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "Mobile App Develeoper",
      date: "Jan 2019 - April 2019",
      subtitle: "HoosOut , Los Angeles, CA",
      paragraph1:
        "Developing an event planning mobile app using IONIC and nodejs for the backend",

      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "Chill out and freelance!",
      date: "June 2018 - Jan 2019",
      subtitle: "Los Angeles, CA",
      paragraph1:
        "Moving from Egypt to the states and enjoying some freelance work and chilling out on Venice beach!",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "Backend web Develeoper",
      date: "April 2017 - Dec 2018",
      subtitle: "Road9media, Maadi, Cairo, Egypt",
      paragraph1:
        "- Development and maintenance of websites and web applications for clients and internal systems.",
      paragraph2:
        "-Maintain existing web projects as well as design and implement new web applications and functionality.",
      paragraph3: "- Building peer to peer online systems .",
      paragraph4: "- Database design.",
      paragraph5:
        "- Creating full API based systems to be developed by any mobile or web development technology.",
      paragraph6: "- Dealing with different kinds of APIs.",
      paragraph7: "Key role achievements:",
      paragraph8:
        "- Improving my object oriented coding skills and writing clean code that helped accelerate development process .",
      paragraph9:
        "- Introducing new versions of laravel like 5.5 , 5.6 , Angular 4 and Vue-JS",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "Backend web Develeoper",
      date: "Oct 2016 - Mar 2017",
      subtitle: "Payme , Cairo, Egypt",
      paragraph1:
        "Building the backend for ( Payme ) the most popular payment gateway in Egypt",
      paragraph2:
        "Creating an Event booking system ( Eventak) and integrating it with Payme",
      paragraph3: "Working with laravel for the first time!",
      paragraph4: "Database design",
      paragraph5:
        "Working with several APIs like AAIB ( African Arab International Bank), Google maps API with its earlier version",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "Military service and Freelancing!",
      date: "Oct 2013 - Oct 2016",
      subtitle: "Cairo, Egypt",
      paragraph1:
        " Working on small projects online on Upwork and freelancer.com ",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
    {
      title: "Graduated! Whoohoo",
      date: "June 2013",
      subtitle: "Computer Science, Helwan University, Egypt",
      contentBG: "#821c1c",
      contentColor: "#e4e4e2",
      arrowStyle: "7px solid  #e4e4e2",
      iconBG: "#f3a712",
      iconColor: "#821c1c",
    },
  ];
  return (
    <VerticalTimeline>
      {WorkPlaces.map((WorkPlace) => {
        return (
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: WorkPlace.contentBG,
              color: WorkPlace.contentColor,
            }}
            contentArrowStyle={{
              borderRight: WorkPlace.arrowStyle,
            }}
            date={WorkPlace.date}
            iconStyle={{ background: "#f3a712", color: "#821c1c" }}
            icon={<CodeIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              {WorkPlace.title}
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              {WorkPlace.subtitle}
            </h4>
            {WorkPlace.paragraph1 ? (
              <p className={classes.paragraphText}>{WorkPlace.paragraph1}</p>
            ) : null}
            {WorkPlace.paragraph2 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph2}
              </p>
            ) : null}
            {WorkPlace.paragraph3 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph3}
              </p>
            ) : null}
            {WorkPlace.paragraph4 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph4}
              </p>
            ) : null}
            {WorkPlace.paragraph5 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph5}
              </p>
            ) : null}
            {WorkPlace.paragraph6 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph6}
              </p>
            ) : null}
            {WorkPlace.paragraph7 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph7}
              </p>
            ) : null}
            {WorkPlace.paragraph8 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph8}
              </p>
            ) : null}
            {WorkPlace.paragraph9 ? (
              <p className={classes.paragraphTextChild}>
                {WorkPlace.paragraph9}
              </p>
            ) : null}
          </VerticalTimelineElement>
        );
      })}

      <VerticalTimelineElement
        iconStyle={{ background: "#f3a712", color: "#fff" }}
        icon={<CodeIcon />}
      />
    </VerticalTimeline>
  );
}
