import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import ProfileCard from "./../components/ProfileCard";

import { TimelineLite } from "gsap";
import IntroCard from "../components/IntroCard";
import WhatIDoCard from "../components/WhatIDoCard";
import { Button, useMediaQuery } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  aboutWrapper: {
    display: "grid",
    gridTemplateColumns: "7fr 15fr",
    gridTemplateRows: "auto 1fr",
    // gridTemplateColumns: "repeat(3,200px)", //example on repeat, that do three columns with the same size
    // gridAutoRows:"15px" // exmple on repeating rows
    gridColumnGap: "10px",
    gridTemplateAreas: `'card main'`,
  },
  aboutWrapperSM: {
    display: "grid",
    justifyItems: "center",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto",
    gridColumnGap: "10px",
    gridTemplateAreas: `'card''main'`,
  },
  profileCardDiv: {
    gridArea: "card",
  },
  mainDiv: {
    gridArea: "main",
  },
  mainDivSM: {
    width: "80%",
    gridArea: "main",
  },
  downloadButton: {
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

const ResumeAbout = () => {
  const smatches = useMediaQuery("(max-width:900px)");
  const classes = useStyles();
  useEffect(() => {
    var timeline = new TimelineLite();
    timeline.from("#profileCardDiv", 0.8, {
      x: -500,
      ease: "circ.out",
    });
    timeline.from("#IntroCard", 0.5, {
      y: 1000,
      ease: "circ.out",
    });
    timeline.from("#WhatIDoCard", 0.5, {
      y: 1000,
      ease: "circ.out",
    });
  }, []);

  return (
    <div className={smatches ? classes.aboutWrapperSM : classes.aboutWrapper}>
      <div className={classes.profileCardDiv} id="profileCardDiv">
        <ProfileCard />
        <Button
          fullWidth
          component={Link}
          to="/files/Fawzy-Atwa-2024.pdf"
          target="_blank"
          variant="contained"
          color="secondary"
          size="large"
          elevation={0}
          className={classes.downloadButton}
          startIcon={<CloudDownloadIcon />}
        >
          Download resume in PDF
        </Button>
        {/* <a
          href="https://www.buymeacoffee.com/fawzytatdev"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            style={{ maxHeight: "60px", marginLeft: "20%" }}
          />
        </a> */}
      </div>
      <div
        className={smatches ? classes.mainDivSM : classes.mainDiv}
        id="mainDiv"
      >
        <div id="IntroCard">
          <IntroCard />
        </div>
        <div id="WhatIDoCard">
          <WhatIDoCard />
        </div>
      </div>
    </div>
  );
};

export default ResumeAbout;
