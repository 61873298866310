import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TimelineLite } from "gsap";

import "react-vertical-timeline-component/style.min.css";
import JobsTimeLine from "../components/JobsTimeLine";
import { Paper, Tabs, Tab } from "@material-ui/core";
import WorkIcon from "@material-ui/icons/Work";
import AppsIcon from "@material-ui/icons/Apps";
import ProjectsTimeLine from "./../components/ProjectsTimeLine";

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
}));

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 100,
      width: "100%",
      backgroundColor: "#f3a712",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export default function ResumeWork() {
  const classes = useStyles({});
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    var timeline = new TimelineLite();
    timeline.from("#tabs", 0.4, {
      y: 500,
      ease: "circ.out",
    });
    timeline.from("#timeline", 1.5, {
      y: 500,
      ease: "circ.out",
    });
  }, []);
  return (
    <div className={classes.workWrapper}>
      <div id="tabs">
        <Paper square className={classes.paperRoot}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            // indicatorColor="primary"
            // textColor="secondary"
            aria-label="icon tabs example"
          >
            <StyledTab label="Career" icon={<WorkIcon />} aria-label="phone" />
            <StyledTab
              label="Projects ( not up to date )"
              icon={<AppsIcon />}
              aria-label="favorite"
            />
          </StyledTabs>
        </Paper>
      </div>

      <div className={classes.timeline} id="timeline">
        {value === 0 ? <JobsTimeLine /> : <ProjectsTimeLine />}
      </div>
    </div>
  );
}
