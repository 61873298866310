import React, { useEffect } from "react";
import { TimelineLite } from "gsap";
import { makeStyles } from "@material-ui/styles";
import heroimg from "../assets/img/vectors/hero.svg";
import tagsvg from "../assets/img/vectors/yellow/11-tag.svg";
import responsivesvg from "../assets/img/vectors/yellow/19-responsive.svg";
import serversvg from "../assets/img/vectors/yellow/18-server.svg";
import { Grid, Card, CardContent } from "@material-ui/core";
import SkillCard from "./../components/SkillCard";

const useStyles = makeStyles((theme) => ({
  blogWrapper: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    // gridTemplateColumns: "repeat(3,200px)", //example on repeat, that do three columns with the same size
    // gridAutoRows:"15px" // exmple on repeating rows
    gridRowGap: "0px",
    gridTemplateAreas: `
    'banner'
    'allskills'
    'managetools'
    `,
  },
  banner: {
    gridArea: "banner",
  },
  skillsContainer: {
    gridArea: "allskills",
  },
  managetools: {
    gridArea: "managetools",
  },
  card: {
    width: "100%",
  },
  cardContentRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
}));

export default function ResumeSkills() {
  const classes = useStyles();
  useEffect(() => {
    var timeline = new TimelineLite();
    timeline.from("#skillsContainer", 0.8, {
      y: 500,
      ease: "circ.out",
    });
    timeline.from("#banner", 0.5, {
      x: -1500,
      ease: "circ.out",
    });
  });
  return (
    <div className={classes.blogWrapper}>
      <div className={classes.banner} id="banner">
        <Grid container justify="center">
          <img
            src={heroimg}
            style={{ width: "80%", height: "auto" }}
            alt="why are you looking at my alt"
          />
        </Grid>
      </div>
      <div className={classes.skillsContainer} id="skillsContainer">
        <Grid container justify="center">
          <Grid item xs={10} md={12}>
            <Card className={classes.card}>
              <CardContent classes={{ root: classes.cardContentRoot }}>
                <Grid container justify="center">
                  {/* <h4
                    style={{
                      color: "#e4e4e2",
                      width: "80%",
                      textAlign: "center"
                    }}
                  > */}
                  {/* <Typography
                    variant="h6"
                    color="textSecondary"
                    style={{
                      color: "#e4e4e2",
                      width: "80%",
                      textAlign: "center",
                    }}
                  >
                    Eight years ago, my first technology stack consisted of
                    Laravel for the backend and Laravel blades for the frontend.
                    However, my current favorite stack involves utilizing
                    Node.js with Fastify and AWS Services for the backend, and
                    React/ Retool with TypeScript for the frontend and admin
                    interface.
                  </Typography> */}

                  {/* </h4> */}
                </Grid>

                <Grid container spacing={0} justify="center">
                  {/* <Grid container > */}
                  <Grid item xs={12} md={4}>
                    <SkillCard
                      title="BACKEND SKILLS"
                      subtitle="Languages I speak"
                      subtitle2="Frameworks I use"
                      subtitle3="Databases & ORMs"
                      subtitle4="Dev Tools"
                      subtitle5="Patterns and Techniques"
                      firstlist={[
                        "Javascript (NodeJs)",
                        "Typescript",
                        "PHP",
                        "Python",
                      ]}
                      secondlist={[
                        "Fastify & Express",
                        "Apollo GraphQL",
                        "Laravel, Slim and Zend",
                      ]}
                      thirdlist={[
                        "MYSQL, PostgreSQL",
                        "Sequelize, Prisma",
                        "Mongoose, GraphQL",
                        "AWS RDS, DynamoDB, Aurora",
                      ]}
                      fourthlist={[
                        "AWS Services",
                        "Retool",
                        "Github Copilot",
                        "Open AI",
                        "Linux",
                      ]}
                      fourthlist2={[
                        "Terraform",
                        "Serverless",
                        "Auth0",
                        "Swagger",
                      ]}
                      fifthlist={[
                        "Object Oriented Programming",
                        "Strategy Design Pattern",
                        "Factory Desing Pattern",
                        "MVC",
                        "Solid principles",
                        "Restful APIs",
                      ]}
                      logo={tagsvg}
                    />
                  </Grid>
                  {/* </Grid> */}

                  {/* <Grid container justify="center"> */}
                  <Grid item xs={12} md={4}>
                    <SkillCard
                      title="FRONTEND SKILLS"
                      variant="dark"
                      subtitle="Favourite Framework"
                      subtitle2="Scripting and styling"
                      firstlist={["React TS, NextJS"]}
                      secondlist={[
                        "Retool",
                        "Material UI",
                        "Redux - state management",
                        "CSS Grid, Flexbox",
                        "GSAP",
                      ]}
                      thirdlist={[]}
                      fourthlist={[]}
                      fourthlist2={[]}
                      fifthlist={[]}
                      logo={responsivesvg}
                    />
                  </Grid>
                  {/* </Grid>
                <Grid container justify="center"> */}
                  <Grid item xs={12} md={4}>
                    <SkillCard
                      title="DEVOPS & Analytics"
                      subtitle="Version Control"
                      subtitle2="CLOUD"
                      subtitle3="CI/CD"
                      firstlist={["GIT"]}
                      secondlist={["AWS"]}
                      thirdlist={[
                        "CircleCI",
                        "Travis",
                        "Docker",
                        "Github Actions",
                        "Netlify",
                        "Heroku",
                        "Forge",
                      ]}
                      logo={serversvg}
                    />
                  </Grid>
                  {/* </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className={classes.managetools} id="managetools"></div>
    </div>
  );
}
