import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Button, useMediaQuery } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import MeIcon from "../assets/img/vectors/icons/me.svg";
import ResumeIcon from "../assets/img/vectors/icons/resume.svg";
// import BlogIcon from "../assets/img/vectors/icons/blog.svg";
import ContactIcon from "../assets/img/vectors/icons/chat.svg";
import WorkIcon from "../assets/img/vectors/icons/work.svg";

import { Tooltip } from "@material-ui/core";
import { Zoom } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  avatar: {
    background: "none",
    border: "0.5px solid #821c1c",
    padding: "10px 10px 10px 10px",
    borderRadius: "10px",
    boxShadow: "0 0 0px #821c1c",
  },
  tooltip: {
    backgroundColor: "#fff",
    color: "#000",
  },
  glowingBtn: {
    padding: "0 0 0 0",
    borderRadius: "10px",
  },
  selectedShade: {
    borderRadius: "10px",
    borderRight: "solid 5px white",
  },
  selectedShadeGray: {
    borderRadius: "10px",
    borderRight: "solid 5px rgba(255,255,255,0.1) ",
  },
}));

const NavIcons = withRouter(({ history, ...props }) => {
  const classes = useStyles();
  const smatches = useMediaQuery("(max-width:900px)");
  // const selected = useState{}
  const [selected, setSelected] = useState(
    props.pagepath ? props.pagepath : "about"
  );
  // console.log(props.pagepath);

  const navigateToPgae = (page) => {
    history.push(`/resume/${page}`);
    setSelected(page);
  };
  return (
    <Grid container direction={smatches ? "row" : "column"} spacing={4}>
      <Grid item={true}>
        <div
          className={
            selected === "about"
              ? classes.selectedShade
              : classes.selectedShadeGray
          }
        >
          <Tooltip
            title="About Me"
            placement="right"
            TransitionComponent={Zoom}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              className={clsx(classes.glowingBtn)}
              color="secondary"
              onClick={() => navigateToPgae("about")}
            >
              <Avatar
                aria-label="#"
                variant="rounded"
                className={classes.avatar}
              >
                <img width="50" src={MeIcon} alt="About Me" />
              </Avatar>
            </Button>
          </Tooltip>
        </div>
      </Grid>
      <Grid item={true}>
        <div
          className={
            selected === "skills"
              ? classes.selectedShade
              : classes.selectedShadeGray
          }
        >
          <Tooltip
            title="Skills"
            placement="right"
            TransitionComponent={Zoom}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              className={classes.glowingBtn}
              color="secondary"
              onClick={() => navigateToPgae("skills")}
            >
              <Avatar
                aria-label="#"
                variant="rounded"
                className={classes.avatar}
              >
                <img width="50" src={ResumeIcon} alt="Skilss" />
              </Avatar>
            </Button>
          </Tooltip>
        </div>
      </Grid>
      <Grid item={true}>
        <div
          className={
            selected === "work"
              ? classes.selectedShade
              : classes.selectedShadeGray
          }
        >
          <Tooltip
            title="Work"
            placement="right"
            TransitionComponent={Zoom}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              className={classes.glowingBtn}
              color="secondary"
              onClick={() => navigateToPgae("work")}
            >
              <Avatar
                aria-label="#"
                variant="rounded"
                className={classes.avatar}
              >
                <img width="50" src={WorkIcon} alt="Work" />
              </Avatar>
            </Button>
          </Tooltip>
        </div>
      </Grid>
      {/* <Grid item={true}>
        <div
          className={
            selected === "blog"
              ? classes.selectedShade
              : classes.selectedShadeGray
          }
        >
          <Tooltip
            title="Blog"
            placement="right"
            TransitionComponent={Zoom}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              className={classes.glowingBtn}
              color="secondary"
              onClick={() => navigateToPgae("blog")}
            >
              <Avatar
                aria-label="#"
                variant="rounded"
                className={classes.avatar}
              >
                <img width="50" src={BlogIcon} alt="Blog" />
              </Avatar>
            </Button>
          </Tooltip>
        </div>
      </Grid> */}
      <Grid item={true}>
        <div
          className={
            selected === "contact"
              ? classes.selectedShade
              : classes.selectedShadeGray
          }
        >
          <Tooltip
            title="Contact"
            placement="right"
            TransitionComponent={Zoom}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              className={classes.glowingBtn}
              color="secondary"
              onClick={() => navigateToPgae("contact")}
            >
              <Avatar
                aria-label="#"
                variant="rounded"
                className={classes.avatar}
              >
                <img width="50" src={ContactIcon} alt="Contact" />
              </Avatar>
            </Button>
          </Tooltip>
        </div>
      </Grid>
    </Grid>
  );
});

export default NavIcons;
