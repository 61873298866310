import TextField from "@material-ui/core/TextField";

import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& .MuiFormLabel-root": {
      color: "#000",
      fontWeight: 900
    },
    "& .MuiInputBase-input": {
      fontWeight: 800
    },
    "& .MuiFormHelperText-root": {
      color: "#821c1c",
      fontWeight: 800
    },
    "& label.Mui-focused": {
      color: "#821c1c"
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#821c1c"
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "821c1c"
      },
      "&:hover fieldset": {
        borderColor: "821c1c"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#821c1c"
      }
    }
  }
})(TextField);

export default CssTextField;
