import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { ListItemAvatar } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import { List } from "@material-ui/core";
import CodeIcon from "@material-ui/icons/Code";
import CloudIcon from "@material-ui/icons/Cloud";
import DnsIcon from "@material-ui/icons/Dns";
// import PaymentIcon from "@material-ui/icons/Payment";
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
    marginBottom: "10px",
    // backgroundImage: `url(${cardTexture})`,
    // backgroundSize: "cover"
  },
  headerRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },

  cardContentRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  headerTitle: {
    color: "rgba(243, 167, 18, 1)",
    fontWeight: 900,
    fontSize: "1.2rem",
  },
  headerSubHeader: {
    fontWeight: 900,
    color: "#262626",
    fontSize: "0.9rem",
  },
  inline: {
    display: "inline",
  },
  avatar: {
    backgroundColor: "#e4e4e2",
  },

  textSecondary: { color: "#e4e4e2" }, //white gray
  textPrimary: { color: "#262626" }, // black gray
  headerPrimary: {
    color: "#821c1c",
    fontWeight: 900,
    fontSize: "1rem",
  },

  headerSecondary: {
    color: "rgba(243, 167, 18, 1)",
    fontWeight: 900,
    fontSize: "1rem",
  },
  subHeaderSecondary: {
    color: "#e4e4e2",
    fontWeight: 600,
    fontSize: "0.875rem",
  },
}));

export default function WhatIDoCard() {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardHeader
        title="What I do?"
        classes={{
          title: classes.headerTitle,
          subheader: classes.headerSubHeader,
          root: classes.headerRoot,
        }}
      />
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <CodeIcon className={classes.textSecondary} />
                </ListItemAvatar>
                <ListItemText
                  primary="API Design and Development"
                  classes={{
                    primary: classes.headerSecondary,
                    secondary: classes.textSecondary,
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={clsx(
                          classes.inline,
                          classes.subHeaderSecondary
                        )}
                      >
                        API development
                      </Typography>
                      {
                        " — Creating robust and efficient APIs for both internal and external consumption. Proficient in designing and implementing RESTful frameworks and GraphQL APIs, ensuring seamless integration with 3rd party systems and tools. Securing APIs using best practices, with a keen focus on authentication and authorization methodologies. Skilled in utilizing authentication tools such as Auth0 to enhance API security. "
                      }
                      <pre></pre>
                      <Typography
                        component="span"
                        variant="body2"
                        className={clsx(
                          classes.inline,
                          classes.subHeaderSecondary
                        )}
                      >
                        Managing the complete software development process from
                        conception to deployment
                      </Typography>

                      {
                        " — Maintaining and upgrading the software following deployment, Managing the end-to-end life cycle for the production of software and applications,  Overseeing and guiding the analyzing, writing, building, and deployment of software, Overseeing the automated testing and providing feedback to management during the development process "
                      }
                    </React.Fragment>
                  }
                />
              </ListItem>

              <Divider variant="inset" component="li" />

              {/* <ListItem alignItems="flex-start">
                <ListItemAvatar> */}
              {/* <Avatar alt="Web Design" src={ResumeIcon} /> */}

              {/* <ColorLensIcon className={classes.textSecondary} />
                </ListItemAvatar>
                <ListItemText
                  primary="Web Design"
                  classes={{
                    primary: classes.headerSecondary,
                    secondary: classes.textSecondary,
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={clsx(
                          classes.inline,
                          classes.subHeaderSecondary
                        )}
                      >
                        Material design is my favorite
                      </Typography>
                      {
                        " —  Before Jumping into coding my main passion was web design and producing interactive websites, now I'm trying to focus more on the accessabilty, fast page speed and goal driven web design"
                      }
                    </React.Fragment>
                  }
                />
              </ListItem> */}

              <Divider variant="inset" component="li" />

              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <SettingsInputCompositeIcon
                    className={classes.textSecondary}
                  />
                </ListItemAvatar>
                {/* <ListItemText
                  primary="Devops"
                  classes={{
                    primary: classes.headerSecondary,
                    secondary: classes.textSecondary,
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={clsx(
                          classes.inline,
                          classes.subHeaderSecondary
                        )}
                      >
                        Docker fan!
                      </Typography>
                      {
                        " — Using docker and robust technologies like Forge, CircleCI, Travis and kubernetes I can provide soultions to set up the CI / CD process."
                      }
                    </React.Fragment>
                  }
                /> */}
                <ListItemText
                  primary="AI"
                  classes={{
                    primary: classes.headerSecondary,
                    secondary: classes.textSecondary,
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={clsx(
                          classes.inline,
                          classes.subHeaderSecondary
                        )}
                      >
                        GPT Enthusiast!
                      </Typography>
                      {
                        " — Skilled in vector DB searching, prompt engineering and Large Language Model (LLM), I love diving into AI technologies and always keep up with the latest advancements. My expertise in programming, algorithms, and LLM allows me to develop innovative solutions and contribute to cutting-edge projects in the dynamic world of AI."
                      }
                    </React.Fragment>
                  }
                />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} md={6}>
            <List className={classes.root}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <DnsIcon className={classes.textSecondary} />
                </ListItemAvatar>
                <ListItemText
                  primary="Cloud Solutions"
                  classes={{
                    primary: classes.headerSecondary,
                    secondary: classes.textSecondary,
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={clsx(
                          classes.inline,
                          classes.subHeaderSecondary
                        )}
                      >
                        AWS Certified Solution architect
                      </Typography>
                      {
                        " — Creating cost-effective, fault-tolerant systems on AWS that have high availability and are highly scalable."
                      }
                    </React.Fragment>
                  }
                />
              </ListItem>

              <Divider variant="inset" component="li" />

              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <CloudIcon className={classes.textSecondary} />
                </ListItemAvatar>
                <ListItemText
                  primary="IAC - Infrastructure As Code"
                  classes={{
                    primary: classes.headerSecondary,
                    secondary: classes.textSecondary,
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={clsx(
                          classes.inline,
                          classes.subHeaderSecondary
                        )}
                      >
                        Infrastructure as code - Using Terraform and AWS
                        CloudFormation
                      </Typography>
                      {
                        " —  is the process of managing and provisioning computer data centers through machine-readable definition files, rather than physical hardware configuration or interactive configuration tools!"
                      }
                    </React.Fragment>
                  }
                />
              </ListItem>

              {/* <Divider variant="inset" component="li" />

              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <PaymentIcon className={classes.textSecondary} />
                </ListItemAvatar>
                <ListItemText
                  primary="Ecommerce - SPAs"
                  classes={{
                    primary: classes.headerSecondary,
                    secondary: classes.textSecondary,
                  }}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={clsx(
                          classes.inline,
                          classes.subHeaderSecondary
                        )}
                      >
                        Yes I do some WordPress and I'm not proud
                      </Typography>
                      {
                        " — Familiar with CMSs like WordPress and dropal for building to go projects and websites"
                      }
                    </React.Fragment>
                  }
                />
              </ListItem> */}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
