import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { ListItemAvatar } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { List } from "@material-ui/core";

import BuildIcon from "@material-ui/icons/Build";

import DoneIcon from "@material-ui/icons/Done";
// import Bounce from "react-reveal/Bounce";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    marginBottom: "10px",
    // backgroundImage: `url(${cardTexture})`,
    // backgroundSize: "cover"
  },
  headerRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },

  cardContentRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  headerTitle: {
    color: "rgba(243, 167, 18, 1)",
    fontWeight: 900,
    fontSize: "1.2rem",
    textAlign: "center",
  },
  headerSubHeader: {
    fontWeight: 900,
    color: "#262626",
    fontSize: "0.9rem",
  },
  inline: {
    display: "inline",
  },
  avatar: {
    backgroundColor: "#e4e4e2",
  },

  textSecondary: { color: "#e4e4e2" }, //white gray
  textPrimary: { color: "#262626" }, // black gray
  headerPrimary: {
    color: "#821c1c",
    fontWeight: 900,
    fontSize: "1rem",
  },

  headerSecondary: {
    color: "rgba(243, 167, 18, 1)",
    fontWeight: 900,
    fontSize: "1rem",
  },
  subHeaderSecondary: {
    color: "#e4e4e2",
    fontWeight: 600,
    fontSize: "0.875rem",
  },
  skillLogo: {
    maxHeight: "70px",
    margin: "20px 20px 20px 20px",
  },
  ListItem: {
    padding: 0,
  },
}));

export default function SkillCard(props) {
  const {
    title,
    subtitle,
    subtitle2,
    subtitle3,
    subtitle4,
    subtitle5,
    firstlist,
    secondlist,
    thirdlist,
    fourthlist,
    fourthlist2,
    fifthlist,
    // variant,
  } = props;

  const classes = useStyles();
  return (
    <Grid
      container

      //   style={{ backgroundColor: variant === "dark" ? "#000" : "#FFF" }}
    >
      {/* <Bounce></Bounce>
      <Bounce></Bounce> */}

      <Grid container justify="center">
        <Grid item xs={6} md={6}>
          <img className={classes.skillLogo} src={props.logo} alt="backend" />
          <Typography variant="h5" color="textSecondary">
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="flex-start" style={{ marginTop: "20px" }}>
        <Grid item xs={10} md={10}>
          <Typography variant="h6" color="textSecondary">
            {subtitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <List className={classes.root}>
          {firstlist
            ? firstlist.map((item) => {
                return (
                  <ListItem
                    key={item}
                    className={classes.ListItem}
                    alignItems="flex-start"
                  >
                    <ListItemAvatar>
                      <DoneIcon className={classes.textSecondary} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item}
                      classes={{
                        primary: classes.headerSecondary,
                        secondary: classes.textSecondary,
                      }}
                    />
                  </ListItem>
                );
              })
            : null}
        </List>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} md={12}>
          <Typography variant="h6" color="textSecondary">
            {subtitle2}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <List className={classes.root}>
          {secondlist
            ? secondlist.map((item) => {
                return (
                  <ListItem
                    className={classes.ListItem}
                    alignItems="flex-start"
                    key={item}
                  >
                    <ListItemAvatar>
                      <DoneIcon className={classes.textSecondary} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item}
                      classes={{
                        primary: classes.headerSecondary,
                        secondary: classes.textSecondary,
                      }}
                    />
                  </ListItem>
                );
              })
            : null}
        </List>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} md={12}>
          <Typography variant="h6" color="textSecondary">
            {subtitle3}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <List className={classes.root}>
          {thirdlist
            ? thirdlist.map((item) => {
                return (
                  <ListItem
                    key={item}
                    className={classes.ListItem}
                    alignItems="flex-start"
                  >
                    <ListItemAvatar>
                      <DoneIcon className={classes.textSecondary} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item}
                      classes={{
                        primary: classes.headerSecondary,
                        secondary: classes.textSecondary,
                      }}
                    />
                  </ListItem>
                );
              })
            : null}
        </List>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} md={12}>
          <Typography variant="h6" color="textSecondary">
            {subtitle4}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <List className={classes.root}>
          {fourthlist
            ? fourthlist.map((item) => {
                return (
                  <ListItem
                    key={item}
                    className={classes.ListItem}
                    alignItems="flex-start"
                  >
                    <ListItemAvatar>
                      <BuildIcon className={classes.textSecondary} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item}
                      classes={{
                        primary: classes.headerSecondary,
                        secondary: classes.textSecondary,
                      }}
                    />
                  </ListItem>
                );
              })
            : null}
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <List className={classes.root}>
          {fourthlist2
            ? fourthlist2.map((item) => {
                return (
                  <ListItem
                    key={item}
                    className={classes.ListItem}
                    alignItems="flex-start"
                  >
                    <ListItemAvatar>
                      <BuildIcon className={classes.textSecondary} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item}
                      classes={{
                        primary: classes.headerSecondary,
                        secondary: classes.textSecondary,
                      }}
                    />
                  </ListItem>
                );
              })
            : null}
        </List>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} md={12}>
          <Typography variant="h6" color="textSecondary">
            {subtitle5}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <List className={classes.root}>
          {fifthlist
            ? fifthlist.map((item) => {
                return (
                  <ListItem
                    key={item}
                    className={classes.ListItem}
                    alignItems="flex-start"
                  >
                    <ListItemAvatar>
                      <DoneIcon className={classes.textSecondary} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={item}
                      classes={{
                        primary: classes.headerSecondary,
                        secondary: classes.textSecondary,
                      }}
                    />
                  </ListItem>
                );
              })
            : null}
        </List>
      </Grid>
    </Grid>
  );
}
