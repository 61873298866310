import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Image from "../assets/img/bg/48-min.png";
import Logo from "../assets/img/logo/logo.png";

import { TweenMax, TimelineLite } from "gsap";
import {
  useMediaQuery,
  Paper,
  Typography,
  Button,
  Grid,
} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { withRouter } from "react-router";
import ParticlesDeveloper from "../components/ParticlesDeveloper";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import SendMessageForm from "./../components/SendMessageForm";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(375px, 1fr))",
    height: "100vh",
    overflowY: "hidden",
  },
  areaOneWrapper: {
    position: "relative",
    backgroundColor: "#f2f2f2",
    zIndex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  areaTwoWrapper: {
    position: "relative",
    backgroundColor: "#262626",
    zIndex: "2",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  areaOneImageDiv: {
    backgroundImage: `url(${Image})`,
    backgroundSize: "cover",
    backgroundPosition: "center center", // use left center or right center
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
  },
  areaTwoParticlesDiv: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
  },
  mainLogo: {
    position: "absolute",
    bottom: 0,
    right: "-23%",
  },
  mainLogoMobile: {
    position: "absolute",
    bottom: "-25%",
    left: "25%",
  },
  logoImg: { maxHeight: "60vh" },
  logoImgMobile: { maxHeight: "40vh" },
  hideMe: {
    display: "none",
  },
  bottomLabel: {
    display: "inline-block",
    position: "absolute",
    bottom: -2,
    left: "-15%",
  },
  bottomLabelMobile: {
    position: "fixed",
    bottom: -2,
    width: "100%",
    zIndex: 4,
  },
  bottomLabelRoot: {
    padding: 10,
    backgroundColor: "#821c1c",
    color: "#f2f2f2",
  },
  button: {
    padding: "10px 5px 10px 5px",
    marginBottom: "20%",
    width: "40%",
    fontWeight: 900,
    fontSize: "1rem",
    // backgroundColor: "#feb800"
  },
  SendMessageButton: {
    padding: "10px 5px 10px 5px",
    marginTop: "50px",
    width: "80%",
    fontWeight: 900,
    fontSize: "1rem",
    // backgroundColor: "#feb800"
  },
  contentText: {
    fontWeight: 600,
    fontSize: "0.9rem",
  },
  dialogRoot: {
    backgroundColor: "#000",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Landing = withRouter(({ history }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const isWeb = useMediaQuery("(min-width:750px)");

  useEffect(() => {
    console.log("test");
  });
  // var timelineStart = new TimelineLite();
  TweenMax.from("#areaOneImageDiv", 2, { opacity: 0, x: 50 });
  TweenMax.from("#areaTwoParticlesDiv", 2, { opacity: 0, x: -50 });
  TweenMax.from("#mainLogo", 2, { opacity: 0, x: 50 });
  TweenMax.from("#mainLogoMobile", 2, { opacity: 0, y: 50 });
  TweenMax.from("#hireMeBtn", 1.2, { opacity: 0, x: 1900, ease: "circ.out" });
  TweenMax.from("#resumeBtn", 1.2, { opacity: 0, x: -1900, ease: "circ.out" });
  TweenMax.from("#bottomLabel", 1.2, { opacity: 0, y: -500, ease: "bounce" });

  const openResume = () => {
    history.replace("resume");
  };

  const goToResume = () => {
    var timeline = new TimelineLite({ onComplete: openResume });
    timeline.to("#areaOneImageDiv", 0.5, { opacity: 0, x: 50 });
    timeline.to("#areaTwoParticlesDiv", 0.5, { opacity: 0, x: -50 }, "-=0.5");
    timeline.to("#hireMeBtn", 0.5, { opacity: 0, ease: "circ.out" }, "-=0.5");
    timeline.to("#resumeBtn", 0.5, { opacity: 0, ease: "circ.out" }, "-=0.5");
    timeline.to("#bottomLabel", 0.5, { opacity: 0, y: 500, ease: "bounce" });
    timeline.to("#mainLogo", 0.4, { opacity: 0, x: 50 }, "-=0.5");
    if (!isWeb) {
      timeline.to("#mainLogoMobile", 0.4, { opacity: 0, y: 50 }, "-=0.5");
    }

    // timeline.to("#areaOneWrapper", 0.1, { opacity: 0, x: -500 });
    timeline
      .to("#areaOneWrapper", 0.3, { width: 0 }, 1)
      .to("#areaOneWrapper", 0.3, isWeb ? { right: 400 } : { top: -500 }, 1);
    timeline.to("#areaTwoWrapper", 0.1, { opacity: 0 }, "-=0.1");
  };
  const landingFooter = () => {
    return (
      <div
        id="bottomLabel"
        className={isWeb ? classes.bottomLabel : classes.bottomLabelMobile}
      >
        <Paper className={classes.bottomLabelRoot} elevation={0}>
          <Typography
            variant="body2"
            align="center"
            className={classes.contentText}
          >
            Welcome! Now it's time to Choose your own adventure!
          </Typography>
        </Paper>
      </div>
    );
  };

  const resumeBtn = () => {
    return (
      <Button
        id="resumeBtn"
        variant="contained"
        color="secondary"
        elevation={0}
        size="medium"
        className={classes.button}
        onClick={goToResume}
      >
        Check resume
      </Button>
    );
  };

  const hireMeBtn = () => {
    return (
      <Button
        id="hireMeBtn"
        variant="contained"
        color="secondary"
        size="large"
        elevation={0}
        onClick={handleClickOpen}
        className={classes.button}
      >
        Leave message
      </Button>
    );
  };

  const mainAvatar = () => {
    return (
      <>
        <div
          id="mainLogo"
          className={isWeb ? classes.mainLogo : classes.hideMe}
        >
          <img src={Logo} alt="logo" className={classes.logoImg} />
        </div>
        <div
          id="mainLogoMobile"
          className={!isWeb ? classes.mainLogoMobile : classes.hideMe}
        >
          <img src={Logo} alt="logo" className={classes.logoImgMobile} />
        </div>
      </>
    );
  };
  return (
    <div className={classes.wrapper}>
      <div id="areaOneWrapper" className={classes.areaOneWrapper}>
        <div id="areaOneImageDiv" className={classes.areaOneImageDiv}></div>
        {mainAvatar()}
        {hireMeBtn()}
      </div>
      <div id="areaTwoWrapper" className={classes.areaTwoWrapper}>
        <div id="areaTwoParticlesDiv" className={classes.areaTwoParticlesDiv}>
          <ParticlesDeveloper />
        </div>
        {resumeBtn()}
        {landingFooter()}
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            boxShadow: "none",
          },
        }}
      >
        <Grid container direction="row" justify="flex-end">
          <IconButton color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Grid item xs={10} sm={3}>
            <SendMessageForm close={handleClose} />
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
});

export default Landing;
