//This one is not realy used, we use the history to track the current page, THIS IS JUST AS A PLACEHOLDER
const currentPageReducer = (state = "about", action) => {
  switch (action.type) {
    case "BLOG_PAGE":
      return (state = "blog");
    default:
      return state;
  }
};

export default currentPageReducer;
