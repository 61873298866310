import { createMuiTheme } from "@material-ui/core/styles";

// const breakpoints = createBreakpoints({});
const FawzyTheme = createMuiTheme({
  typography: {
    fontFamily: "'Quicksand', sans-serif",
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
      fontSize: "1.2rem",
    },
    h6: {
      fontWeight: 400,
      fontSize: "1rem",
    },
  },
  // overrides: {
  //   MuiTypography: {
  //     h5: {
  //       fontSize: "1rem"
  //     }
  //   }
  // },
  palette: {
    //DEEP Blue
    primary: {
      //#Usage : <Typography variant="h4" color="primary">
      main: "#262626",
      // main: blue.A200,
      // light: lightBlue.A200,
      // dark: blue.A700
    },
    //RED
    secondary: {
      main: "#821c1c",
      dark: "#4f0707",
    },
    error: {
      main: "#821c1c",
      dark: "#4f0707",
    },
    info: {
      main: "#821c1c",
      dark: "#4f0707",
    },
    success: {
      main: "#28a745",
    },
    action: {
      main: "#f3a712",
    },

    text: {
      //#Usage : <Typography variant="h4" color="textPrimary">
      primary: "#821c1c",
      secondary: "#e4e4e2",
    },
    warning: {
      main: "#fff",
    },
  },
});

export default FawzyTheme;
